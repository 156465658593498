import React from "react";

import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import PrivateComponent from "./Private/PrivateComponent";
import Dashboard from "./Pages/Dashboard";
import Customer from "./Pages/Customers";
import CreateCustomer from "./Pages/Customers/create";
import EditCustomer from "./Pages/Customers/update";
import Items from "./Pages/Items";
import CreateItem from "./Pages/Items/create";
import EditItem from "./Pages/Items/update";
import CreateQuote from "./Pages/Quotation/CreateQuote";
import AllQuotation from "./Pages/Quotation";
import UpdateQuote from "./Pages/Quotation/UpdateQuote";
import ViewQuote from "./Pages/Quotation/ViewQuote";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateComponent />}>
          <Route path="/dashboard" element={<Dashboard />} />
          {/* Customers */}
          <Route path="/customers" element={<Customer />} />
          {/* <Route path="/customer/create" element={<CreateCustomer />} />
          <Route path="/customer/edit/:id" element={<EditCustomer />} /> */}
          {/* Customers */}
          {/* Items */}
          <Route path="/items" element={<Items />} />
          <Route path="/item/create" element={<CreateItem />} />
          <Route path="/item/edit/:id" element={<EditItem />} />
          {/* Items */}
          {/* Quote */}
          <Route path="/all-quotations" element={<AllQuotation />} />
          <Route path="/create-quote" element={<CreateQuote />} />
          <Route path="/update-quote/:id" element={<UpdateQuote />} />
          <Route path="/view-quote/:id" element={<ViewQuote />} />

          {/* Quote */}
        </Route>
      </Routes>
    </>
  );
};

export default App;
