import React from "react";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard - PWSYS</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
