import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import QuotationService from "../../services/QuotationServices";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";
import "./print.css";

const ViewQuote = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [isPrinting, setIsPrinting] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    onBeforeGetContent: () => setIsPrinting(true),
    onAfterPrint: () => setIsPrinting(false),
  });
  const getData = () => {
    setIsLoading(true);
    QuotationService.GetQuoteById(params?.id)
      .then((res) => {
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [params?.id]);

  return (
    <>
      <Helmet>
        <title>View Quotation - PWSYS</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {/* <h4 className="mb-0">{quoteNumber} </h4> */}
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">All Quotes</Link>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div
                  className={`card ${isPrinting ? "print-scale-60" : ""}`}
                  style={{ margin: 0 }}
                >
                  <div className="card-body" ref={componentRef}>
                    <div
                      className="invoice-title"
                      style={{
                        backgroundColor: "#d6dce4",
                        padding: 20,
                      }}
                    >
                      <div className="float-end">
                        {/* Quotation {data?.quota_number} */}
                        <div className="text-muted mt-3">
                          <p className="mb-1">
                            Civic Center St, Block C Phase 1 Johar Town,
                            <br />
                            Lahore
                          </p>
                          <p className="mb-1">
                            <i className="uil uil-envelope-alt me-1" />
                            connect@purewave.pk
                          </p>
                          <p>
                            <i className="uil uil-phone me-1" /> 0321-8490704
                            <br />
                            <i className="uil uil-phone me-1" /> 0300-4536046
                          </p>
                        </div>
                      </div>

                      <div className="mb-4">
                        {/* <img
                        src="/assets/images/pw_logo.png"
                        alt="logo"
                        height={120}
                        // className="logo-dark"
                      /> */}
                        <img
                          src="/assets/images/pw_logo.png"
                          alt="logo"
                          width={180}
                        />
                      </div>
                    </div>
                    <hr className="my-4" />
                    <div
                      className="row"
                      style={{ paddingLeft: 25, paddingRight: 25 }}
                    >
                      <div className="col-sm-6">
                        <div className="text-muted ">
                          <h4 className="mb-3">Quote To:</h4>
                          <h5
                            className="font-size-15"
                            style={{ fontWeight: "bold" }}
                          >
                            {data?.customer_details?.name}
                          </h5>
                          <p className="mb-1">
                            {data?.customer_details?.address}
                          </p>
                          <p>{data?.customer_details?.phone_number}</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-muted text-sm-end">
                          <div>
                            <h5
                              className="font-size-16 mb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              Quote No:
                            </h5>
                            <p>{data?.quota_number}</p>
                          </div>
                          <div className="mt-4">
                            <h5
                              className="font-size-16 mb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              Quote Date:
                            </h5>
                            <p>
                              {moment(data?.create_date).format("MMM DD,YYYY")}
                            </p>
                          </div>
                          <div className="mt-4">
                            <h5
                              className="font-size-16 mb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              Valid till:
                            </h5>
                            <p>
                              {moment(data?.valid_date).format("MMM DD,YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="py-2"
                      style={{ paddingLeft: 25, paddingRight: 25 }}
                    >
                      <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {data?.subject_line}
                      </h5>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-centered mb-0">
                          <thead
                            style={{
                              backgroundColor: "#333f4f",
                              color: "#fff",
                            }}
                          >
                            <tr>
                              <th style={{ width: 70 }}>No.</th>
                              <th>Item</th>
                              <th> </th>
                              <th>UOM</th>
                              <th
                                className="text-center"
                                style={{ width: 120 }}
                              >
                                Qty/Job
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.items_details?.map((el, index) => (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "" : "#f2f2f2",
                                  }}
                                >
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    <h5
                                      className="font-size-15 mb-1"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {el.item}
                                    </h5>
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">
                                        {el.description}
                                      </li>
                                    </ul>
                                  </td>
                                  <td> </td>
                                  <td>{el?.UOM}</td>
                                  <td className="text-center">{el?.Qty}</td>
                                </tr>
                              ))}
                            <th
                              scope="row"
                              colSpan={4}
                              className="border-0 text-end"
                            >
                              <h4 className="m-0">Quotation Total</h4>
                            </th>
                            <td className="border-0 text-end">
                              <h4 className="m-0">
                                <div
                                  style={{
                                    backgroundColor: "#333f4f",
                                    color: "#fff",
                                    padding: 10,
                                  }}
                                >
                                  Rs.{" "}
                                  {parseInt(
                                    data?.total_proposed
                                  )?.toLocaleString()}
                                </div>
                              </h4>
                            </td>
                          </tbody>
                        </table>

                        <div
                          style={{
                            border: 1,
                            borderColor: "#f2f2f2",
                            borderStyle: "solid",
                            padding: 10,
                            marginTop: 20,
                          }}
                        >
                          <p>
                            <h4
                              className="font-size-16 mb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              Note:
                            </h4>{" "}
                            Please note that the estimates provided in this
                            proposal are preliminary and subject to change based
                            on the outcome of the site survey. Any additional
                            requirements or adjustments identified during the
                            survey will be added to the proposal accordingly. We
                            strive to provide accurate estimates and transparent
                            pricing to our clients, and we appreciate your
                            understanding that unforeseen factors may impact the
                            final proposal.
                          </p>
                          <p>
                            Our quotation includes the supply, installation, and
                            commissioning of a solar power system for your
                            residential/commercial property as specified in the
                            BOQ. Anything not mentioned in the BOQ is not part
                            of Scope.
                          </p>
                          <p>
                            AC cables are included only if the inverter is near
                            the main distribution box; additional charges may
                            apply for distances beyond 5 meters. Please note
                            that internal load management, wiring alteration, or
                            the provision of 2-Core/4-Core cable (from pole to
                            meter) are not included in the scope of this
                            quotation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-print-none m-4">
                    <div className="float-end">
                      <span
                        onClick={handlePrint}
                        // href="javascript:window.print()"
                        className="btn btn-success waves-effect waves-light me-1"
                      >
                        <i className="fa fa-print" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewQuote;
