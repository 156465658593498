import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomerServices from "../../services/CustomerServices";
import moment from "moment";
import Table from "../../Reuseable/Table";
import QuotationService from "../../services/QuotationServices";
import { formatWithCommas } from "../../utils/helper";
import { Helmet } from "react-helmet";

const AllQuotation = () => {
  const navigate = useNavigate();
  const columns = [
    "#",
    "Customer Name",
    "Address",
    "Subject Line",
    "Proposed",
    "Created By",
    "Created At",
  ];

  const [quotes, setQuotes] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsloading] = useState(false);

  const handleQuotaClick = (id) => {
    navigate(`/view-quote/${id}`);
  };

  const getData = useCallback(() => {
    setIsloading(true);
    QuotationService.GetAllQuotes()
      .then((res) => {
        // console.log("res.data", res.data);

        let newData = [];
        for (let i = 0; i < res.data?.length; i++) {
          const element = res.data[i];
          let obj = {
            "#": (
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => handleQuotaClick(element?._id)}
              >
                {element?.quota_number}
              </span>
            ),
            id: element?._id,
            "Customer Name": element?.customer_details?.name,
            Address: element?.customer_details?.address,
            "Subject Line": element?.subject_line,
            Proposed: `Rs ${formatWithCommas(element?.total_proposed)}`,
            "Created By":
              element?.created_by?.first_name +
              " " +
              element?.created_by?.last_name,
            "Created At": moment(element?.createdAt).format("MMM DD,YYYY"),
          };
          newData.push(obj);
        }
        setQuotes(newData);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (id) => {
    QuotationService.DeleteQuotes(id)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(err));
  };
  const handleEdit = (id) => {
    navigate(`/update-quote/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>All Quotations - PWSYS</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Quotes</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">All Quotes</Link>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <button
                          type="button"
                          onClick={() => navigate("/create-quote")}
                          className="btn btn-primary btn-sm waves-effect waves-light"
                          style={{ marginLeft: "5px" }}
                        >
                          <i
                            className="mdi mdi-plus"
                            style={{ marginRight: "5px" }}
                          />
                          Create Quote
                        </button>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12" style={{ marginTop: "10px" }}>
              <div className="card">
                <div className="card-body">
                  <Table
                    columns={columns}
                    data={quotes}
                    itemsPerPage={itemsPerPage}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    setItemsPerPage={setItemsPerPage}
                    addButton="Add Post"
                    addButtonLink={() => navigate("/post/create")}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllQuotation;
