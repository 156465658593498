import requests from "./httpService";

const ItemServices = {
  GetAllItems() {
    return requests.get(`/item/all`);
  },
  GetItemById(id) {
    return requests.get(`/item/${id}`);
  },
  CreateItem(body) {
    return requests.post(`/item/create`, body);
  },
  UpdateItem(id, body) {
    return requests.put(`/item/${id}`, body);
  },
  DeleteItem(id) {
    return requests.delete(`/item/${id}`);
  },
  ChangeStatus(id, body) {
    return requests.put(`/item/status/${id}`, body);
  },
};

export default ItemServices;
