import request from "./httpService";

const QuotationService = {
  CreateQuotaion(body) {
    return request.post(`/quotation/create`, body);
  },
  GetAllQuotes() {
    return request.get(`/quotation/all`);
  },
  DeleteQuotes(id) {
    return request.delete(`/quotation/${id}`);
  },
  GetQuoteById(id) {
    return request.get(`/quotation/${id}`);
  },
  UpdateQuote(id, body) {
    return request.put(`/quotation/${id}`, body);
  },
};

export default QuotationService;
