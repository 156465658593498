import requests from "./httpService";

const CustomerServices = {
  GetAllCustomer() {
    return requests.get(`/customer/all`);
  },
  GetCustomerById(id) {
    return requests.get(`/customer/${id}`);
  },
  CreateCustomer(body) {
    return requests.post(`/customer/create`, body);
  },
  UpdateCustomer(id, body) {
    return requests.put(`/customer/${id}`, body);
  },
  DeleteCustomer(id) {
    return requests.delete(`/customer/${id}`);
  },
};

export default CustomerServices;
