import React, { useState } from "react";
import CustomerServices from "../../services/CustomerServices";

const CreateCustomerPopup = (props) => {
  const {
    name,
    address,
    phoneNumber,
    setName,
    setAddress,
    setPhoneNumber,
    isLoading,
    setIsLoading,
    handleCreateCustomer,
  } = props;

  return (
    <div
      id="customerCreateModal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-modal="true"
      style={{ display: "none", paddingLeft: 0 }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="myModalLabel">
              Create New Customer
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => handleCreateCustomer(e)}>
              <div className="mb-3">
                <label className="form-label" for="formrow-firstname-input">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label" for="formrow-firstname-input">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-input"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" for="formrow-firstname-input">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-input"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light waves-effect"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={(e) => handleCreateCustomer(e)}
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};

export default CreateCustomerPopup;
