import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomerServices from "../../services/CustomerServices";
import moment from "moment";
import Table from "../../Reuseable/Table";
import { Helmet } from "react-helmet";

const Customer = () => {
  const navigate = useNavigate();
  const columns = ["#", "Name", "Address", "Phone Number", "Created At"];

  const [allCustomers, setAllCustomers] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [itemToEdit, setItemToEdit] = useState(null);

  const [isLoading, setIsloading] = useState(false);

  const getAllCustomers = useCallback(() => {
    setIsloading(true);
    CustomerServices.GetAllCustomer()
      .then((res) => {
        let newData = [];
        for (let i = 0; i < res.data?.length; i++) {
          const element = res.data[i];
          let obj = {
            "#": i + 1,
            id: element?._id,
            Name: element?.name,
            Address: element?.address,
            "Phone Number": element?.phone_number,
            "Created At": moment(element?.createdAt).format("MMM DD,YYYY"),
          };
          newData.push(obj);
        }
        setAllCustomers(newData);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  }, []);

  useEffect(() => {
    getAllCustomers();
  }, []);

  const handleDelete = (id) => {
    CustomerServices.DeleteCustomer(id)
      .then((res) => {
        getAllCustomers();
      })
      .catch((err) => console.log(err));
  };
  const handleEdit = (id) => {
    setItemToEdit(id);
    CustomerServices.GetCustomerById(id)
      .then((res) => {
        setName(res.data.name);
        setAddress(res.data.address);
        setPhoneNumber(res.data.phone_number);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateCustomer = (e) => {
    e.preventDefault();

    setIsloading(true);

    const body = {
      name: name,
      address: address,
      phone_number: phoneNumber,
    };

    CustomerServices.CreateCustomer(body)
      .then((res) => {
        setName("");
        setAddress("");
        setPhoneNumber("");
        setItemToEdit(null);
        getAllCustomers();
        // setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
      });
  };

  const handleEditCustomer = (e) => {
    e.preventDefault();

    setIsloading(true);

    const body = {
      name: name,
      address: address,
      phone_number: phoneNumber,
    };

    CustomerServices.UpdateCustomer(itemToEdit, body)
      .then((res) => {
        setIsloading(false);
        setName("");
        setAddress("");
        setPhoneNumber("");
        setItemToEdit(null);
        getAllCustomers();
        // setItemToEdit(null);
      })
      .catch((err) => {
        setIsloading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Customers - PWSYS</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Customers</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">All Customers</Link>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {/* <button
                          type="button"
                          onClick={() => navigate("/customer/create")}
                          className="btn btn-primary btn-sm waves-effect waves-light"
                          style={{ marginLeft: "5px" }}
                        >
                          <i
                            className="mdi mdi-plus"
                            style={{ marginRight: "5px" }}
                          />
                          Add Customer
                        </button> */}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="col-lg-12">
                <div className="row">
                  <div class="col-lg-3">
                    <div class="card">
                      <div class="card-body">
                        <form
                          className="col-lg-12"
                          onSubmit={(e) => handleCreateCustomer(e)}
                        >
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                for="formrow-firstname-input"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-input"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>

                            <div className="mb-3">
                              <label
                                className="form-label"
                                for="formrow-firstname-input"
                              >
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-input"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                for="formrow-firstname-input"
                              >
                                Phone Number
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                id="formrow-firstname-input"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            </div>
                            <div className="col-md-12">
                              <button
                                onClick={(e) => {
                                  itemToEdit
                                    ? handleEditCustomer(e)
                                    : handleCreateCustomer(e);
                                }}
                                className="btn btn-primary"
                                type="submit"
                                style={{ width: "100%" }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9">
                    <div class="card">
                      <div class="card-body">
                        <Table
                          columns={columns}
                          data={allCustomers}
                          itemsPerPage={itemsPerPage}
                          onDelete={handleDelete}
                          onEdit={handleEdit}
                          setItemsPerPage={setItemsPerPage}
                          addButton="Add Post"
                          addButtonLink={() => navigate("/post/create")}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
