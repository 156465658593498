import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomerServices from "../../services/CustomerServices";
import ItemServices from "../../services/ItemServices";
import QuotationService from "../../services/QuotationServices";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import { notifyError } from "../../utils/toast";
import {
  calculatePercentageChange,
  formatWithCommas,
} from "../../utils/helper";
import { Helmet } from "react-helmet";
import CreateCustomerPopup from "./createCustomerPopup";

const CreateQuote = () => {
  const navigate = useNavigate();

  const [adminInfo, setAdminInfo] = useState(null);

  useEffect(() => {
    const AdminData = JSON.parse(secureLocalStorage.getItem("adminInfo"));
    setAdminInfo(AdminData);
  }, []);

  const [allCustomers, setAllCustomers] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [categories, setCategories] = useState([]);
  const [itemsByCategory, setItemsByCategory] = useState({});
  // items
  const [systemType, setSystemType] = useState("");
  const [systemSize, setSystemSize] = useState("");
  const [actualSize, setActualSize] = useState("");
  const [quoteDate, setQuoteDate] = useState(moment().format("YYYY-MM-DD"));
  const [validDate, setValidDate] = useState("");

  const [totalCost, setTotalCost] = useState("");
  const [totalProposed, setTotalProposed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setValidDate(
      moment(quoteDate, "YYYY-MM-DD").add(3, "days").format("YYYY-MM-DD")
    );
  }, [quoteDate]);

  // Fields in Quotation
  const [items, setItems] = useState([
    {
      item: "",
      itemname: "",
      description: "",
      UOM: "",
      Qty: "1",
      CUnitPrice: "",
      CUnitTotal: "",
      PUnitPrice: "",
      PUnitTotal: "",
    },
  ]);

  const changeActualSize = () => {
    const solarpanele = items.find((el) => el.item === "Solar Panel");
    const selected_item = allItems.find(
      (el) => el.name === solarpanele?.itemname
    );
    const initial_quantity = systemSize / selected_item?.size;
    const quantity = Math.ceil(initial_quantity) || 1;

    const actualSize = quantity * selected_item?.size;
    setActualSize(actualSize);
  };

  const updateItems = (index, selected_item, currentSystemSize) => {
    const updatedItems = [...items];

    if (selected_item?.category === "Solar Panel") {
      const initial_quantity = currentSystemSize / selected_item?.size;
      const quantity = Math.ceil(initial_quantity) || 1;

      const actualSize = quantity * selected_item?.size;
      setActualSize(actualSize);
      updatedItems[index].Qty = quantity;
      updatedItems[index].UOM = selected_item?.uom;
      updatedItems[index].description = selected_item?.description;
      updatedItems[index].CUnitPrice = Math.round(
        selected_item?.buying_price * selected_item?.size
      );
      updatedItems[index].CUnitTotal = Math.round(
        selected_item?.buying_price * selected_item?.size * quantity
      );
      updatedItems[index].PUnitPrice = Math.round(
        selected_item?.selling_price * selected_item?.size
      );
      updatedItems[index].PUnitTotal = Math.round(
        selected_item?.selling_price * selected_item?.size * quantity
      );
    } else if (selected_item?.category === "Structure") {
      if (selected_item?.name.includes("L2")) {
        const panel_quantity = updatedItems.find(
          (item) => item?.item === "Solar Panel"
        )?.Qty;
        const initial_quantity = panel_quantity / 2;
        const quantity = Math.ceil(initial_quantity) || 1;

        updatedItems[index].Qty = quantity;
        updatedItems[index].UOM = selected_item?.uom;
        updatedItems[index].description = selected_item?.description;
        updatedItems[index].CUnitPrice = selected_item?.buying_price;
        updatedItems[index].CUnitTotal = selected_item?.buying_price * quantity;
        updatedItems[index].PUnitPrice = selected_item?.selling_price;
        updatedItems[index].PUnitTotal =
          selected_item?.selling_price * quantity;
      } else if (selected_item?.name.includes("L3")) {
        const panel_quantity = updatedItems.find(
          (item) => item?.item === "Solar Panel"
        )?.Qty;
        const initial_quantity = panel_quantity / 3;
        const quantity = Math.ceil(initial_quantity) || 1;

        updatedItems[index].Qty = quantity;
        updatedItems[index].UOM = selected_item?.uom;
        updatedItems[index].description = selected_item?.description;
        updatedItems[index].CUnitPrice = selected_item?.buying_price;
        updatedItems[index].CUnitTotal = selected_item?.buying_price * quantity;
        updatedItems[index].PUnitPrice = selected_item?.selling_price;
        updatedItems[index].PUnitTotal =
          selected_item?.selling_price * quantity;
      } else {
        const panel_quantity = updatedItems.find(
          (item) => item?.item === "Solar Panel"
        );
        const solarPanelItems_size = allItems.find(
          (item) => item?.name === panel_quantity?.itemname
        );
        const initial_quantity = panel_quantity?.Qty;
        const quantity = Math.ceil(initial_quantity) || 1;

        updatedItems[index].Qty = quantity;
        updatedItems[index].UOM = selected_item?.uom;
        updatedItems[index].description = selected_item?.description;
        updatedItems[index].CUnitPrice = Math.round(
          selected_item?.buying_price * solarPanelItems_size?.size
        );
        updatedItems[index].CUnitTotal = Math.round(
          updatedItems[index].CUnitPrice * quantity
        );
        updatedItems[index].PUnitPrice = Math.round(
          selected_item?.selling_price * solarPanelItems_size?.size
        );
        updatedItems[index].PUnitTotal = Math.round(
          updatedItems[index].PUnitPrice * quantity
        );
      }
    } else if (
      selected_item?.category === "Cabling" ||
      selected_item?.category === "Accessories" ||
      selected_item?.category === "Switchgears" ||
      selected_item?.category === "Service" ||
      selected_item?.category === "Civil Work"
    ) {
      const quantity = 1;

      updatedItems[index].Qty = quantity;
      updatedItems[index].UOM = selected_item?.uom;
      updatedItems[index].description = selected_item?.description;
      updatedItems[index].CUnitPrice = Math.round(
        selected_item?.buying_price * actualSize
      );
      updatedItems[index].CUnitTotal = Math.round(
        selected_item?.buying_price * actualSize
      );
      updatedItems[index].PUnitPrice = Math.round(
        selected_item?.selling_price * actualSize
      );
      updatedItems[index].PUnitTotal = Math.round(
        selected_item?.selling_price * actualSize
      );
    } else {
      const quantity = 1;

      updatedItems[index].Qty = quantity;
      updatedItems[index].UOM = selected_item?.uom;
      updatedItems[index].description = selected_item?.description;
      updatedItems[index].CUnitPrice = Math.round(selected_item?.buying_price);
      updatedItems[index].CUnitTotal = Math.round(
        selected_item?.buying_price * quantity
      );
      updatedItems[index].PUnitPrice = Math.round(selected_item?.selling_price);
      updatedItems[index].PUnitTotal = Math.round(
        selected_item?.selling_price * quantity
      );
    }

    setItems(updatedItems);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];

    console.log("name value", name, value);

    if (name === "itemname") {
      const selected_item = allItems.find((el) => el.name === value);
      updateItems(index, selected_item, systemSize);
      updatedItems[index][name] = value;
    } else if (name === "CUnitPrice") {
      updatedItems[index][name] = parseFloat(value.replace(/,/g, ""));
      updatedItems[index].CUnitTotal = Math.round(
        updatedItems[index].CUnitPrice * updatedItems[index].Qty
      );
    } else if (name === "PUnitPrice") {
      updatedItems[index][name] = parseFloat(value.replace(/,/g, ""));
      updatedItems[index].PUnitTotal = Math.round(
        updatedItems[index].PUnitPrice * updatedItems[index].Qty
      );
    } else {
      updatedItems[index][name] = value;
    }

    setItems(updatedItems);
  };

  useEffect(() => {
    changeActualSize();
  }, [items, systemSize]);

  useEffect(() => {
    setItems((currentItems) => {
      const newItems = currentItems.map((item, index) => {
        if (item?.itemname) {
          const selected_item = allItems.find(
            (el) => el.name === item?.itemname
          );
          return updateItems(index, selected_item, systemSize);
        }
        return item;
      });
      return newItems;
    });
  }, [systemSize, actualSize]);

  const handleAddRow = () => {
    setItems([
      ...items,
      {
        item: "",
        itemname: "",
        description: "",
        UOM: "",
        Qty: "1",
        CUnitPrice: "",
        CUnitTotal: "",
        PUnitPrice: "",
        PUnitTotal: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems([]);
    setItems(updatedItems);
  };

  useEffect(() => {
    const cost = items.reduce((acc, item) => acc + (item?.CUnitTotal || 0), 0);
    const propose = items.reduce(
      (acc, item) => acc + (item?.PUnitTotal || 0),
      0
    );

    setTotalCost(cost);
    setTotalProposed(propose);
  }, [items]);

  const handleSelectCustomer = (id) => {
    setSelectedCustomer(allCustomers.find((c) => c._id === id));
  };

  const getAllCustomers = () => {
    CustomerServices.GetAllCustomer()
      .then((res) => {
        setAllCustomers(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleCreateCustomer = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const body = {
      name: name,
      address: address,
      phone_number: phoneNumber,
    };

    CustomerServices.CreateCustomer(body)
      .then((res) => {
        setIsLoading(false);
        // alert("Customer created successfully");
        getAllCustomers();
        setName("");
        setAddress("");
        setPhoneNumber("");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getAllItems = () => {
    ItemServices.GetAllItems()
      .then((res) => {
        const data = res.data;

        setAllItems(data);
        const categoriesSet = new Set(data.map((el) => el.category));
        setCategories([...categoriesSet]);

        const organizedItems = data.reduce((acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        }, {});

        setItemsByCategory(organizedItems);

        // Initialize defaultItems
        const defaultItems = data
          .filter((item) => item.default_selected)
          .map((item) => ({
            item: item.category,
            itemname: item.name,
            description: item.description,
            UOM: item.uom,
            Qty: "1",
            CUnitPrice: item.buying_price,
            CUnitTotal: item.buying_price,
            PUnitPrice: item.selling_price,
            PUnitTotal: item.selling_price,
          }));

        setItems(defaultItems);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllItems();
    getAllCustomers();
  }, []);

  const handleSaveQuotation = (e) => {
    e.preventDefault();

    if (totalProposed == "0") {
      notifyError("Quotation items cannot be empty");
    } else {
      let body = {
        customer_details: selectedCustomer,
        items_details: items,
        created_by: adminInfo,
        valid_date: validDate,
        create_date: quoteDate,
        subject_line: `${
          systemSize / 1000 + "KW" + " " + systemType + " " + "Solar Solution"
        }`,
        company: "",
        system_type: systemType,
        system_size: systemSize,
        actual_size: actualSize,
        total_cost: totalCost,
        total_proposed: totalProposed,
        margin: totalProposed - totalCost,
      };

      QuotationService.CreateQuotaion(body)
        .then((res) => {
          navigate("/all-quotations");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderItemOptions = (category) => {
    return itemsByCategory[category]?.map((item, index) => (
      <option key={index} value={item.name}>
        {item.name}
      </option>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Create Quotation - PWSYS</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create Quote</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">All Items</Link>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-2">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                for="formrow-firstname-input"
                              >
                                System Size
                              </label>
                              <input
                                type="text"
                                className="form-control mb-2"
                                id="formrow-firstname-input"
                                value={systemSize}
                                onChange={(e) => setSystemSize(e.target.value)}
                              />

                              <label
                                className="form-label d-none"
                                for="formrow-firstname-input"
                              >
                                Actual Size
                              </label>
                              <input
                                type="text"
                                className="form-control d-none"
                                id="formrow-firstname-input"
                                value={actualSize}
                                disabled
                                onChange={(e) => setActualSize(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <label
                              className="form-label"
                              for="formrow-firstname-input"
                            >
                              System Type
                            </label>
                            <select
                              className="form-select"
                              data-np-intersection-state="visible"
                              onChange={(e) => setSystemType(e.target?.value)}
                            >
                              <option value="">Select</option>
                              <option value="On-Grid">On Grid</option>
                              <option value="Hybrid">Hybrid</option>
                              <option value="Off-Grid">Off Grid</option>
                            </select>
                          </div>
                          <div className="col-lg-2">
                            <label
                              className="form-label"
                              for="formrow-firstname-input"
                            >
                              Quote to or{" "}
                              <span
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#customerCreateModal"
                              >
                                Add Customer
                              </span>
                            </label>

                            <select
                              className="form-select"
                              data-np-intersection-state="visible"
                              onChange={(e) =>
                                handleSelectCustomer(e.target?.value)
                              }
                            >
                              <option>Select</option>
                              {allCustomers &&
                                allCustomers?.map((customer, index) => (
                                  <option key={index} value={customer?._id}>
                                    {customer?.name}
                                  </option>
                                ))}
                            </select>

                            {/* <p className="mb-1">{selectedCustomer?.address}</p>
                          <p>{selectedCustomer?.phone_number}</p> */}
                          </div>
                          <div className="col-lg-2">
                            <label
                              className="form-label"
                              for="formrow-firstname-input"
                            >
                              Quotation Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="formrow-firstname-input"
                              value={quoteDate}
                              onChange={(e) => setQuoteDate(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-2">
                            <label
                              className="form-label"
                              for="formrow-firstname-input"
                            >
                              Valid Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="formrow-firstname-input"
                              value={validDate}
                              onChange={(e) => setValidDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-2">
                                <label
                                  className="form-label"
                                  for="formrow-firstname-input"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Cost Unit Price -{" "}
                                  {formatWithCommas(totalCost)}
                                </label>
                              </div>
                              <div className="col-lg-2">
                                <label
                                  className="form-label"
                                  for="formrow-firstname-input"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Proposed Unit Price -{" "}
                                  {formatWithCommas(totalProposed)}
                                </label>
                              </div>
                              <div className="col-lg-2">
                                <label
                                  className="form-label"
                                  for="formrow-firstname-input"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Margin -{" "}
                                  {formatWithCommas(totalProposed - totalCost)}
                                </label>
                              </div>
                              <div className="col-lg-2">
                                <label
                                  className="form-label"
                                  for="formrow-firstname-input"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Percentage:{" "}
                                  {calculatePercentageChange(
                                    totalProposed,
                                    totalCost
                                  )}
                                  %
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="py-2">
                      <h5
                        className=""
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {systemSize / 1000 +
                          "KW" +
                          " " +
                          systemType +
                          " " +
                          "Solar Solution"}
                      </h5>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-centered mb-0">
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Item Name</th>
                              <th>Item Description</th>
                              <th>UOM</th>
                              <th>Qty/Job</th>
                              <th>Cost Unit Price</th>
                              <th>Cost Unit Total</th>
                              <th>Proposed Unit Price</th>
                              <th>Proposed Unit Total</th>
                              <th className="text-end">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleAddRow}
                                >
                                  +
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {items &&
                              items?.map((item, index) => {
                                console.log(
                                  `items[${index}]?.itemname`,
                                  items[index]?.itemname
                                );

                                return (
                                  <tr key={index}>
                                    <td>
                                      <select
                                        className="form-select"
                                        data-np-intersection-state="visible"
                                        onChange={(e) => handleChange(index, e)}
                                        name="item"
                                        value={items[index]?.item}
                                      >
                                        <option>Select</option>
                                        {categories &&
                                          categories?.map((item, index) => (
                                            <option key={index} value={item}>
                                              {item}
                                            </option>
                                          ))}
                                      </select>
                                    </td>
                                    <td>
                                      <select
                                        className="form-select"
                                        data-np-intersection-state="visible"
                                        name="itemname"
                                        onChange={(e) => handleChange(index, e)}
                                        value={items[index]?.itemname}
                                      >
                                        <option>Select</option>

                                        {item?.item &&
                                          renderItemOptions(item.item)}
                                      </select>
                                    </td>
                                    <td style={{ width: "300px" }}>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        rows={3}
                                        name="description"
                                        value={items[index]?.description}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="UOM"
                                        value={items[index]?.UOM}
                                        disabled={true}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Qty"
                                        value={items[index]?.Qty || 0}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="CUnitPrice"
                                        value={formatWithCommas(
                                          items[index]?.CUnitPrice
                                        )}
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="CUnitTotal"
                                        disabled={true}
                                        value={
                                          formatWithCommas(
                                            items[index]?.CUnitTotal
                                          ) || 0
                                        }
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="PUnitPrice"
                                        value={
                                          formatWithCommas(
                                            items[index]?.PUnitPrice
                                          ) || 0
                                        }
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="PUnitTotal"
                                        disabled={true}
                                        value={
                                          formatWithCommas(
                                            items[index]?.PUnitTotal
                                          ) || 0
                                        }
                                        onChange={(e) => handleChange(index, e)}
                                      />
                                    </td>

                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => handleRemoveRow(index)}
                                      >
                                        X
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            {/* <tr style={{}}>
                            <th scope="row" colSpan={2} className="text-end">
                              Sub Total
                            </th>
                            <td className="text-end">PKR (CUP) {totalCost}</td>
                            <td className="text-end">
                              PKR(PUP) {totalProposed}
                            </td>
                            <th scope="row" colSpan={2} className="text-end">
                              Margin
                            </th>
                            <td className="text-end">
                              PKR {totalProposed - totalCost}
                            </td>
                          </tr> */}
                            {/* <tr>
                            <th
                              scope="row"
                              colSpan={8}
                              className="border-0 text-end"
                            >
                              Discount :
                            </th>
                            <td className="border-0 text-end"></td>
                            <td className="border-0 text-end">- $50.00</td>
                          </tr> */}

                            <tr>
                              <th
                                scope="row"
                                colSpan={8}
                                className="border-0 text-end"
                              >
                                Total
                              </th>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  PKR {formatWithCommas(totalProposed)}
                                </h4>
                              </td>
                              <td className="border-0 text-end">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleAddRow}
                                >
                                  +
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="d-print-none mt-4">
                        <div className="float-end">
                          {/* <a
                          href="javascript:window.print()"
                          className="btn btn-success waves-effect waves-light me-1"
                        >
                          <i className="fa fa-print" />
                        </a> */}
                          <span
                            onClick={handleSaveQuotation}
                            className="btn btn-primary w-md waves-effect waves-light"
                            style={{ cursor: "pointer" }}
                          >
                            Save
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateCustomerPopup
        name={name}
        address={address}
        phoneNumber={phoneNumber}
        setName={setName}
        setAddress={setAddress}
        setPhoneNumber={setPhoneNumber}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleCreateCustomer={handleCreateCustomer}
      />
    </>
  );
};

export default CreateQuote;
