import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useRoutes } from "react-router-dom";
import ItemServices from "../../services/ItemServices";
import { Helmet } from "react-helmet";

const EditItem = () => {
  const navigation = useNavigate();

  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [size, setSize] = useState("");
  const [uom, setUom] = useState("");

  const GetItemById = () => {
    // e.preventDefault();

    ItemServices.GetItemById(params?.id)
      .then((res) => {
        setCategory(res?.data?.category);
        setName(res?.data?.name);
        setDescription(res?.data?.description);
        setBuyingPrice(res?.data?.buying_price);
        setSellingPrice(res?.data?.selling_price);
        setSize(res?.data?.size);
        setUom(res?.data?.uom);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetItemById();
  }, [params]);

  const handleEditItem = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const body = {
      category: category,
      name: name,
      description: description,
      buying_price: buyingPrice,
      selling_price: sellingPrice,
      size: size,
      uom: uom,
    };

    ItemServices.UpdateItem(params?.id, body)
      .then((res) => {
        setIsLoading(false);
        // alert("Customer created successfully");
        navigation(`/items`);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Update Item - PWSYS</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create Customer</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/customers">Customers</Link>
                      </li>
                      <li className="breadcrumb-item">Create Customer</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={(e) => handleEditItem(e)}>
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          for="formrow-firstname-input"
                        >
                          Category
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          for="formrow-firstname-input"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          for="formrow-firstname-input"
                        >
                          Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          for="formrow-firstname-input"
                        >
                          Buying Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={buyingPrice}
                          onChange={(e) => setBuyingPrice(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          for="formrow-firstname-input"
                        >
                          Selling Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={sellingPrice}
                          onChange={(e) => setSellingPrice(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          for="formrow-firstname-input"
                        >
                          size
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          for="formrow-firstname-input"
                        >
                          U.O.M
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={uom}
                          onChange={(e) => setUom(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12">
                        <button
                          onClick={(e) => handleEditItem(e)}
                          className="btn btn-primary"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditItem;
