function calculatePercentageChange(totalProposed, totalCost) {
  if (totalCost === 0 || totalCost === undefined || totalCost === null) {
    return 0;
  }

  const change = ((totalProposed - totalCost) / totalCost) * 100;
  return parseFloat(change.toFixed(2));
}

function formatWithCommas(number) {
  return number?.toLocaleString();
}

export { calculatePercentageChange, formatWithCommas };
