import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Table from "../../Reuseable/Table";
import ItemServices from "../../services/ItemServices";
import { Helmet } from "react-helmet";

const Items = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);

  const columns = [
    "#",
    "Category",
    "Name",
    "Description",
    "Selected",
    "Buying Price",
    "Selling Price",
    "Size",
    "U.O.M",
    "Created At",
  ];

  const [allItems, setAllItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getData = useCallback(() => {
    setIsloading(true);
    ItemServices.GetAllItems()
      .then((res) => {
        let newData = [];
        for (let i = 0; i < res.data?.length; i++) {
          const element = res.data[i];
          let obj = {
            "#": i + 1,
            id: element?._id,
            Category: element?.category,
            Name: element?.name,
            Description: (
              <div className="description">{element?.description}</div>
            ),
            // Selected: element?.default_selected ? "Yes" : "No",
            Selected: (
              <>
                <input
                  type="checkbox"
                  id={`switch${i + 1}`}
                  switch="none"
                  defaultChecked={element?.default_selected}
                  onChange={(e) => {
                    handleChangeStatus(e, element);
                  }}
                />
                <label
                  htmlFor={`switch${i + 1}`}
                  data-on-label="On"
                  data-off-label="Off"
                />
              </>
            ),
            "Buying Price": element?.buying_price,
            "Selling Price": element?.selling_price,
            Size: element?.size,
            "U.O.M": element?.uom,
            "Created At": moment(element?.createdAt).format("MMM DD,YYYY"),
          };
          newData.push(obj);
        }
        setAllItems(newData);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  }, []);

  const handleChangeStatus = (e, item) => {
    // setIsloading(true);
    e.preventDefault();

    // console.log("item", item);

    let body = {
      default_selected: !item?.default_selected,
    };

    ItemServices.ChangeStatus(item?._id, body)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (id) => {
    ItemServices.DeleteItem(id)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(err));
  };
  const handleEdit = (id) => {
    navigate(`/item/edit/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>Items - PWSYS</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Items</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">All Items</Link>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <button
                          type="button"
                          onClick={() => navigate("/item/create")}
                          className="btn btn-primary btn-sm waves-effect waves-light"
                          style={{ marginLeft: "5px" }}
                        >
                          <i
                            className="mdi mdi-plus"
                            style={{ marginRight: "5px" }}
                          />
                          Add Items
                        </button>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12" style={{ marginTop: "10px" }}>
              <div className="card">
                <div className="card-body">
                  <Table
                    columns={columns}
                    data={allItems}
                    itemsPerPage={itemsPerPage}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    setItemsPerPage={setItemsPerPage}
                    addButton="Add Post"
                    addButtonLink={() => navigate("/item/create")}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Items;
